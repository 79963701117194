<template>
    <div class="empty"></div>
</template>
<script>
    export default {
        data: () => ({}),
        mounted() {},
        methods: {}
    };
</script>
<style lang="less">
    .empty {
    }
</style>
